<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-row class="match-height">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Account Creation Request
            </h4>
            <div class="mb-1 d-flex align-items-center justify-content-center mx-1">
              <div class="col-md-5">
                  <label>Start Date</label>
                  <input
                      type="date"
                      class="form-control"
                      v-model="details.start_date"
                  />
              </div>
              <div class="col-md-5">
                  <label>End Date</label>
                  <input
                      type="date"
                      class="form-control"
                      v-model="details.end_date"
                  />
              </div>
              <div class="col-md-4 mt-2">
                  <button
                      type="button"
                      class="btn-primary btn"
                  >
                      Download
                      <b-spinner
                          v-show="loading"
                          variant="light"
                          small
                      />
                  </button>
              </div>
            </div>            
          </div>

          <div class="card-datatable">
            <table class="datatables-ajax table table-responsive-lg w-100">
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Currency</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Customer Name</th>
                  <th>Currency</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactions"
                  :key="index"
                >
                  <td><span class="mr-2">{{ index + 1 }} .</span>
                    <router-link
                        :to="{
                            name: 'apps-users-view',
                            params: { id: transaction.customer_id },
                        }"
                    >
                        {{  transaction.customer.first_name+' '+transaction.customer.last_name }}
                    </router-link>
                  </td>
                  <td>{{ transaction.currency }}</td>
                  <td>{{ dateformatter(transaction.created_at) }}</td>
                  <td :class="transaction.status === 'failed' ? 'text-danger' : transaction.status === 'pending' ? 'text-warning' : 'text-success'">
                    {{ transaction.status }}
                  </td>
                  <td>
                    <b-button 
                      class="btn btn-sm"
                      variant="primary"
                      v-b-modal.modal-center
                      @click="selectEntry(transaction.id)"
                    >
                        <b-spinner
                          v-show="loading && transaction_id == transaction.id && action == 'approve'"
                          small
                          variant="light"
                        />
                        <feather-icon
                            icon="CheckCircleIcon"
                            size="12"
                            class="text-white"
                        />
                      </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>           
      </div>
    </b-row>
    <div class="mx-2 mb-2">
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in meta.links" :key="link.id">
            <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
          </li>
        </ul>
      </nav>        
    </div>
    <b-modal
      id="modal-center"
      centered
      title="Account Request Approval"
      hide-footer
      size="lg"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Currency"
              label-for="currency"
            >
              <b-form-input
                v-model="payload.currency"
                name="currency"
                placeholder="Currency"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Account Type"
              label-for="account_type"
            >
              <b-form-input
                v-model="payload.account_type"
                name="account_type"
                placeholder="Account Type"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Occupation"
              label-for="occupation"
            >
              <b-form-input
                v-model="payload.occupation"
                name="occupation"
                placeholder="Occupation"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Income Band"
              label-for="income_band"
            >
              <b-form-input
                v-model="payload.income_band"
                name="income_band"
                placeholder="Income Band"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Account Designation"
              label-for="account_designation"
            >
              <b-form-input
                v-model="payload.account_designation"
                name="account_designation"
                placeholder="Account Designation"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Employment Status"
              label-for="employment_status"
            >
              <b-form-input
                v-model="payload.employment_status"
                name="employment_status"
                placeholder="Employment Status"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Address Line 1"
              label-for="address_line1"
            >
              <b-form-input
                v-model="payload.address_line1"
                name="address_line1"
                placeholder="Address Line 1"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Address Line 2"
              label-for="address_line2"
            >
              <b-form-input
                v-model="payload.address_line1"
                name="address_line2"
                placeholder="Address Line 2"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h5 class="mt-3">KYC Documents</h5>
            <div v-if="kycs && kycs.length">
              <div class="" v-for="kyc in kycs" :key="kyc.id" v-show="kyc.verification_status === 'PENDING'">
                <div class="d-flex align-items-center justify-content-between mb-1">
                  <div>
                    <strong>{{kyc.file_name}}</strong>
                    <a :href="kyc.file_path" target="_blank" class="ml-2">
                      <strong>Click to view</strong>
                    </a>
                  </div>
                  <div>
                    <strong 
                      :class="kyc.verification_status && kyc.verification_status.toLowerCase() === 'approved' ? 'text-success' 
                      : (kyc.verification_status && kyc.verification_status.toLowerCase() === 'pending' ? 'text-warning' : 'text-danger')"
                    >
                      {{ kyc.verification_status}}
                    </strong>

                    <b-dropdown
                      variant="link"
                      no-caret
                      class="p-0"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item 
                        v-if="kyc.verification_status && kyc.verification_status.toLowerCase() === 'pending'"
                        @click="actionConfirmation(kyc.id, 'approve', 'kyc')"
                      >
                        <feather-icon icon="CheckCircleIcon" />
                        <span class="align-middle ml-50">Approve</span>
                      </b-dropdown-item>
        
                      <b-dropdown-item 
                        v-if="kyc.verification_status && kyc.verification_status.toLowerCase() === 'pending'"
                        @click="actionConfirmation(kyc.id, 'rejected', 'kyc')"
                      >
                        <feather-icon icon="XCircleIcon" />
                        <span class="align-middle ml-50">Reject</span>
                      </b-dropdown-item>
        
                      <b-dropdown-item @click="actionConfirmation(kyc.id, 'delete', 'kyc')">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>   
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p>No KYC uploaded</p>
            </div>
          </b-col>
        </b-row>

        <b-form-group class="d-flex align-items-center justify-content-between gap-3"> 
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3 mr-2 "
            variant="primary"
            @click="manageTransaction(payload.id, 'approve')"
          >
            Create Account
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3"
            variant="danger"
            @click="manageTransaction(payload.id, 'decline')"
          >
              Decline Request
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>          
        </b-form-group>
      </b-form>
    </b-modal> 
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink,
  BBadge, BPagination, BSpinner, BButton,
  BInputGroup, BFormGroup, BForm, BDropdown,
  BInputGroupAppend, BDropdownItem,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Button from '@/views/components/button/Button.vue'
import Swal from 'sweetalert2';
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BForm,
    BDropdown,
    BDropdownItem,

    vSelect,
    Button,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      transactions: [],
      searchQueryParam: '',
      searchQuery: {
        text: '',
        status: '',
      },
      meta: {},
      links: {},
      url: '',
      statusOptions: [
        'success',
        'pending',
        'failed',
      ],
      details: {
        start_date:'',
        end_date: ''
      },
      loading: false,
      filter: false,
      filter_value: '',
      transaction_id: '',
      action: '',
      payload: {},
      kycs: []
    }
  },
  computed: {
    transactionFilter() {
      return this.transactions.filter(trans => trans.type === this.searchQuery.text || trans.beneficiary.toLowerCase().includes(this.searchQuery.text) || trans.beneficiary.includes(this.searchQuery.text) || trans.status === this.searchQuery.status)
    },
  },
  created() {
    this.url = this.$url+'/wallets/wallet-requests'
    this.getAccountRequests()
  },
  methods: {
    dateformatter(isoString) {
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(new Date(isoString));
    },
    paginate(url) {
      this.url = url
      this.getAccountRequests()
    },     
    getAccountRequests() {
      this.$http.get(this.url)
        .then(response => {
          if (response.data.status) {
            this.transactions = response.data.data.data
            this.links = response.data.data.links ?? []
            this.meta = response.data.data.meta ?? {}
          }
        })
    },
    getKycs() {
      this.$http.get(`${this.$url}/compliance/customer/${this.payload.customer_id}`)
        .then(response => {
          if (response.data.status) {
            this.kycs = response.data.data ?? []
          }
        })
    },
    searchList() {
      this.url = `${this.$url}/wallets/wallet-requests?filter_by=${this.searchQueryParam}&filter=${this.filter_value}`;
      this.getAccountRequests()
    }, 
    handleFilterOptions(event) {
      this.searchQueryParam = event.target.value;
    },
    selectEntry(id) {
      this.payload = this.transactions.filter(entry => entry.id == id)[0]
      this.getKycs()
    },
    manageTransaction(id, option) {
      Swal.fire({
        title: 'Warning',
        icon: 'warning',
        text: `Are you sure you want to ${option} this request?`,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed',
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if(result.isConfirmed) {
          this.transaction_id = id
          this.action = option
          this.loading = true
          this.$http.post(this.$url+'/bridgecard-wallets/manage', {request_id: id, option: option})
          .then(response => {
              if(response.data.status) {
                Swal.fire({
                  icon: "success",
                 text: response.data.message,
                 showCloseButton: true,
                })
                .then(() => {
                  window.location.reload()
                })
              }
              else {
                Swal.fire({
                  title: 'Error',
                  icon: "error",
                 text: response.data.message,
                 cancelButtonText: 'OK'
                })
              }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
              cancelButtonText: 'OK'
            })
          })
          .finally(() => this.loading = false)
        }
      })
    },
    manageDocument(document_id, option) {
      this.loading = true
      this.$http.get(`${this.$url}/compliance/verify-document/${document_id}/${option}`)
        .then(() => {
          this.getKycs()
        })
        .catch( err => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    actionConfirmation(document_id, option, model) {
      Swal.fire({
        title: `Are you sure?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes! Proceed'
      })
      .then((result) => {
        if (result.isConfirmed && model === 'kyc') {
            this.manageDocument(document_id, option);
        } 
        else {
          Swal.close();
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
